.range-slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #afb5b4;
    border-bottom: 5px solid #afb5b4;
    border-radius: 5px;
}

.range-slider::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: #3A7689;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: #3A7689;
}